<template>
    <div>
        <TableList
            :columns="columns"
            :get_table_list="this.get_table_list"
            :slot_table_list="['operation','status']"
            :is_page="false"
            rowKey="id"
            ref="list"
            @list_after="list_after"
        >
            <template slot="left_btn">
                <a-button class="float_left" v-auth="'change'" @click="add()" type="primary">添加菜单</a-button>
            </template>
            <template slot="status" slot-scope="data">
                <status :value="data.text"></status>
            </template>
            <template slot="operation" slot-scope="data">
                <a @click="edit(data.record,'add')">添加</a>
                <a-divider type="vertical" />
                <a v-auth="'change'" @click="edit(data.record)">编辑</a>
                <a-divider type="vertical" />
                <a-popconfirm title="您确定要删除吗?" v-auth="'delete'" @confirm="del(data.record)">
                    <a>删除</a>
                </a-popconfirm>
            </template>
        </TableList>
        <EditPop @handleSubmit="submit" :form_data="form_data" :visible.sync="visible"></EditPop>
    </div>
</template>
<script>
// @ is an alias to /src
import EditPop from "@/components/EditPop";
import Form from "@/components/Form";
import TableList from "@/components/TableList";
import { menuList, saveMenu, delMenu } from '@/api/system'
const columns = [
    {
        title: "菜单名称",
        dataIndex: "title",
    },
    {
        title: "ID",
        dataIndex: "id",
    },
    {
        title: "链接",
        dataIndex: "url",
    },
    {
        title: "状态",
        dataIndex: "status",
        scopedSlots: {
            customRender: "status"
        }
    },
    {
        title: "操作",
        scopedSlots: {
            customRender: "operation"
        }
    }
];

export default {
    name: "Index",
    components: {
        EditPop,
        Form,
        TableList
    },
    data() {
        return {
            get_table_list: menuList,
            columns,
            visible: false,
            form_data: {
                title: "添加",
                list: [
                    {
                        type: 'text',
                        name: 'id',
                        hidden: true,
                        options: {},
                    },
                    {
                        type: "tree-select",
                        name: "pid",
                        title: "上级菜单",
                        list: [],
                        options: {
                            initialValue: 0
                        }
                    },
                    {
                        type: "text",
                        name: "title",
                        title: "菜单名称",
                        options: {}
                    },
                    {
                        type: "text",
                        name: "url",
                        title: "菜单链接",
                        options: {}
                    },
                    {
                        type: "text",
                        name: "params",
                        title: "链接参数",
                        options: {}
                    },
                    {
                        type: "text",
                        name: "icon",
                        title: "菜单图标",
                        options: {}
                    },
                    {
                        type: "text",
                        name: "sort",
                        title: "排序",
                        options: {}
                    },
                    {
                        type: "radio",
                        name: "status",
                        title: "状态",
                        options: {
                            initialValue: 1
                        },
                        list: this.$config.status_list
                    },
                ]
            }
        };
    },
    created() {

    },
    methods: {
        list_after() {

            let list = this.$method.get_depart_list(this.$refs.list.list, 'children', 'id', 'title');

            this.form_data.list = this.$method.set_form_list(this.form_data.list, 'pid', 'treeData', [{ key: 0, value: 0, title: '顶部菜单' }, ...list]);
        },
        add() {
            this.form_data.title = "添加"

            this.$refs.list.$refs.form_seo.form.resetFields();

            this.form_data.list.forEach(item => {
                if(item.name == 'pid'){
            		item.options.initialValue = 0;
            	}else{
            		item.options.initialValue = '';
            	}
            })
            this.visible = true;
        },
        del(data) {
            delMenu({
                data: {
                    id: data.id
                },
                info: true,
            }).then(res => {
                this.$refs.list.get_list();
            })
        },
        edit(data, type = 'edit') {

            if (type == 'add') {
                this.form_data.title = "添加"
                this.$refs.list.$refs.form_seo.form.resetFields();
                this.form_data.list.forEach(item => {
                    if (item.name == 'pid') {
                        item.options.initialValue = data['id'];
                    } else {
                        item.options.initialValue = "";
                    }
                })
            } else {
                this.form_data.title = "编辑"
                this.form_data.list.forEach(item => {
                    item.options.initialValue = data[item.name];
                })
            }

            this.visible = true;
        },
        submit(e) {
            if (!e.values.id) {
                e.values.id = '';
            }
            saveMenu({
                data: e.values,
                info: true,
            }).then(res => {
                this.visible = false;
                this.$refs.list.get_list();
            })
        }

    }
};
</script>

